import React, { useCallback, useEffect } from 'react';
import AdminHeader from '../../../layout/adminheader/AdminHeader';
import { shopColumnName } from '../../../constants/ShopConstants';
import ShopTable from '../../../tables/ShopTable';
import { getShopOwnerApprovedProfiles } from '../../../apiServices/Shop/ShopApiServices';
import { useDispatch, useSelector } from 'react-redux';
import { shopActions } from '../../../store/shop/ShopSlice';

const Shops = () => {
  const dispatch = useDispatch()
  const approvedProfilesData = useSelector((state) => state.shop.approvedOwnersProfile)
  // const [approvedProfilesData, setApprovedProfilesData] = useState([])

  const getApprovedProfilesData = useCallback(() => {
    getShopOwnerApprovedProfiles()
      .then((res) => {
        if (res?.data.length > 0)
          dispatch(shopActions.setApprovedOwnersProfile(res?.data))
        // console.log("Approved Profile Data",res?.data)
      })
  }, [dispatch])

  useEffect(() => {
    getApprovedProfilesData()
  }, [getApprovedProfilesData])

  // console.log("Approved Profiles are", approvedProfilesData)

  return (
    <div className="middleData">
      <AdminHeader title="Shop Owners" />
      <ShopTable
        shopColumnName={shopColumnName}
        approvedProfilesData={approvedProfilesData}
      />
    </div>
  );
};

export default Shops;
