import instance from "../axiosInstance";

export const createShop = async (shopData) => {
    try {
        const response = await instance.post('/shops', shopData); // POST request
        return response.data;
    } catch (error) {
        console.error('Error creating shop:', error);
        throw error;
    }
};
export const getShop = async (shopData) => {
    try {
        const response = await instance.get('/shops');
        return response.data;
    } catch (error) {
        console.error('Error creating shop:', error);
        throw error;
    }
};

export const getShopOwnerApprovedProfiles = async () => {
    try {
        const response = await instance.get('/admin/ownerprofile/all/true')
        return response.data
    } catch (error) {
        console.error('Error fetching customers', error)
        return error
    }
}

export const getShopsByOwnerProfileId = async (ownerProfileId) => {
    try {
        const response = await instance.get(`/admin/shop/all/${ownerProfileId}`)
        return response.data
    } catch (error) {
        console.error('Error fetching shops', error)
        return error
    }
}

export const getOwnerProfileId = async (ownerProfileId) => {
    try {
        const response = await instance.get(`/admin/ownerprofile/${ownerProfileId}`)
        return response.data
    } catch (error) {
        console.error('Error fetching shops', error)
        return error
    }
}